import {
  Column,
  Container,
  Grid,
  Paragraph,
  ResponsivePadding,
  Row,
  Spacer,
  Title,
} from '@jp-olvera/jp-viaducto-components';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Helmet from '../../Helmet';
import { Footer, ProjectCard, Layout, Header } from '../components';
import { useAnimatedScroll } from '../hooks/useAnimatedScroll';
import { useProjects } from '../hooks/useProjectData';
import ConfigurationProvider from '../providers/ConfigurationProvider';

const StyledColumnImage = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledResponsivePadding = styled(ResponsivePadding)`
  margin-bottom: 50;
  overflow: hidden;
`;

export default function Projects() {
  const projectData = useProjects();
  useAnimatedScroll();

  const swapProjects = () => {
    const elements = [];
    let counter = 0;
    for (let i = 0; i < projectData.length; i++) {
      let isEven = counter % 2 === 0;
      const evenSwapMd = isEven ? 7 : 5;
      const evenSwapXl = isEven ? 8 : 4;
      const isLast = i === projectData.length - 1;
      const project = projectData[i];
      const element = (
        <StyledColumnImage
          xs={12}
          md={isLast ? 12 : evenSwapMd}
          xl={isLast ? 12 : evenSwapXl}
          key={project.name + i}
        >
          <ProjectCard project={project} src={project.gallery[0]} />
        </StyledColumnImage>
      );

      elements.push(element);

      counter = isEven ? counter + 1 : i;
    }
    return elements;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'pageView',
        page: {
          name: 'All Projects',
        },
      });
    }
  }, []);

  const headerBubbles = {
    first: {
      to: '/',
      text: 'Home',
    },
    second: {
      to: '/about',
      text: 'About Me',
    },
  };

  return (
    <ConfigurationProvider>
      <Helmet title='Projects' />
      <Layout>
        <Header bubbles={headerBubbles} />
        <Container expandHorizontal expandVertical vertical='xxxl'>
          <StyledResponsivePadding top={null}>
            <Container horizontal='md' vertical='lg' expandHorizontal>
              <Title level='D3' color='white' lineHeight='xl'>
                All my main projects, take a look 👻
              </Title>
              <Spacer size='md' />
              <Paragraph color='white'>
                Here you can find some of mt projects, not all of them are
                public, I need some privacy sometimes 😅
              </Paragraph>
              <Spacer size='md' />
            </Container>
            <Container horizontal={'xs'} expandHorizontal>
              <Grid expanded gutter={0}>
                <Row>{swapProjects()}</Row>
              </Grid>
            </Container>
          </StyledResponsivePadding>
          <Footer />
        </Container>
      </Layout>
    </ConfigurationProvider>
  );
}
